







































import bus from "@/bus";
import {Component, Vue} from "vue-property-decorator";

@Component
export default class UserList extends Vue {
  public pageSize = 20
  public pageNum = 1
  public totality = 0
  public columns = [
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: "center",
      width: 200
    }, {
      title: '手机号',
      dataIndex: 'phone',
      align: "center",
      width: 200
    }, {
      title: '昵称',
      dataIndex: 'nickName',
      align: "center",
      width: 200
    }, {
      title: '设备数量',
      dataIndex: 'deviceNum',
      align: "center",
      width: 100
    }, {
      title: '店铺名称',
      dataIndex: 'store.storeName',
      align: "center"
    }, {
      title: '地址',
      dataIndex: 'store.address',
      align: "center"
    }, {
      title: '操作',
      dataIndex: 'operation',
      scopedSlots: {customRender: 'operation'},
      width: 150,
      align: "center"
    }
  ];
  public data = [];

  public mounted() {
    this.getUserList()
  }

  // 获取用户
  public getUserList() {
    bus.$post("/user/userListPage", {
      pageSize: this.pageSize,
      pageNum: this.pageNum
    }).then(res => {
      console.log(res);
      this.data = res.data.list
      this.totality = res.data.totalNum
    })
  }

  public onShowSizeChange() {
    console.log("翻页");
  }

  public onDetails(data,dd) {
    console.log(data);
    console.log(dd);
  }
}
